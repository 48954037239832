.edit-user-permissions-container {

    .permissions-header-container,
    .permissions-container {
        margin-bottom: 20px;
    }

    .loading-container {
        padding-top: 50px;
        padding-bottom: 70px;
    }

}