.change-password-form.padding {
    padding: 1.2rem;
}

.change-password-form {

    .top-margin {
        margin-top: 1.6rem;
    }

    .form-content {
        min-width: 450px;
    }

    .form-content .field {
        margin-bottom: 1.5rem;
    }

    .form-content .field>label {
        font-size: 0.92rem !important;
    }

    .logut-button {
        margin-top: 0.6rem;
    }

    .form-title {
        text-align: center;
        margin-bottom: 1.8rem;
        font-size: 14px;
        font-weight: 600;
    }

    .form-description {
        margin-bottom: 1rem;
        text-align: center;
        color: #28a745;
    }

    @media screen and (max-width: 960px) {
        .form-content {
            width: 80%;
        }
    }

    @media screen and (max-width: 640px) {
        .form-content {
            width: 100%;
            min-width: 0;
        }
    }
}