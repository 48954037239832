.user-profile-container {

    .user-image-skeleton {
        margin: auto;
    }

    .avatar-image {
        width: 100px;
        height: 100px;
    }
}