.finish-school-demo-expiration-form {
    padding: 1.2rem;

    .top-margin {
        margin-top: 1.6rem;
    }

    .form-content {
        min-width: 450px;
    }

    .logut-button {
        margin-top: 0.6rem;
    }

    .form-title {
        text-align: center;
        margin-bottom: 1.8rem;
        font-size: 15px;
        font-weight: 600;
    }

    .form-description {
        margin-bottom: 1.5rem;
        text-align: right;
        color: #28a745;
    }

    .form-description-icon {
        font-size: 0.5rem;
        margin-left: 0.5rem;
    }

    @media screen and (max-width: 960px) {
        .form-content {
            width: 80%;
        }
    }

    @media screen and (max-width: 640px) {
        .form-content {
            width: 100%;
            min-width: 0;
        }
    }
}