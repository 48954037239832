.app-layout {

    .layout-menu {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .user-info * {
        text-align: right;
    }

    .avatar-image {
        width: 42px;
        height: 42px;
    }

    .layout-topbar-logo {
        cursor: pointer;
        // padding: 0 !important;

        justify-content: right !important;
        padding-right: 1.35rem !important;

        .app-label {
            color: #FBC02D;
        }
    }

    .layout-topbar-logo>img {
        // width: 60%;
        width: 38px;
        height: 38px;
    }

    .layout-topbar-right {
        padding-right: 0 !important;
    }

    .layout-topbar-right-content {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 28px;
        padding-left: 28px;
        cursor: default;
    }

    .user-name,
    .school-name {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.96rem !important;
    }

    .user-active {
        margin-top: 0.2rem;
    }

    .clock-container {
        direction: ltr;
        float: left;
    }

    .menu-item-group-text {
        margin-right: 0.2rem;
        padding-bottom: 0.2rem;
        font-weight: 500;
        color: #495057;
        border-bottom: 1px solid #e3f2fd;
        display: block;
    }

    .menu-item-group-splitter {
        display: block;
        height: 0.35rem;
    }

    @media only screen and (max-width: 459px) {
        .layout-topbar-right-content {
            display: none;
        }
    }

}