.delete-filter-button {
    margin: 0.2rem !important;
}

// .filters-dialog {
//     max-height: 500px;
// }

.filters-container {
    min-width: 450px;
}

.global-filter-input-container {
    input {
        min-width: 320px;
    }
}

.field-value-label {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    margin-bottom: 0.5rem !important;
}

.direction-ltr {
    direction: ltr;
}

.list-lookup-field-value {
    caret-color: transparent;
}

.list-lookup-field-value-container {
    position: relative;
}

.list-lookup-field-value-clear {
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
    cursor: pointer;
}

.ltr-input {
    direction: ltr !important;
    text-align: right !important;
}

.details-table .p-datatable-thead>tr>th {
    background: #F7F7F7 !important;
}

.details-table .p-datatable-thead>tr>th,
.details-table .p-datatable-tbody>tr>td {
    padding: 0.3rem !important;
}

.font-size-9 {
    font-size: 0.9rem !important;
}

.mt-1-5 {
    margin-top: 0.3rem;
}

.mt-0-5 {
    margin-top: 0.05rem;
}

small.p-error {
    margin-top: 0.25rem !important;
}

.h-0-7rem {
    height: 0.7rem !important;
}

.h-1-2rem {
    height: 1.2rem !important;
}

.small-list-button {
    padding: 0 !important;
    width: 1.76rem !important;
    height: 1.7rem !important;
}

.audit-log-field-label {
    width: 100%;
    height: 100%;
    padding-top: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.audit-log-field-empty-value {
    height: 35px;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    cursor: default;
}

.audit-log-arrow-icon {
    position: absolute;
    top: 19px;
    right: -6px;
    font-size: 12px;
    font-weight: bold;
    color: #0082cc;
}

.m-0-1 {
    margin: 0.1rem;
}

.visibility-hidden {
    visibility: hidden;
}

.float-left {
    float: left;
}

.word-break-break-word {
    word-break: break-word !important;
}

.white-space-normal {
    white-space: normal !important;
}