.landing-page-container {

    background-color: white;

    .landing-page-image {
        clip-path: polygon(92% 0%, 0% 0%, 0% 100%, 100% 100%);
    }

    @media screen and (max-width: 767px) {
        .landing-page-image {
            clip-path: unset;
        }
    }

}