.dashboard-container {

    .small-grid-column-content {

        width: calc(100% - 30px);
        height: 34px;

        >span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: default;
        }

    }

    .user-counts-progress-bar {
        margin-top: 0.2rem !important;
        height: 14px !important;

        .p-progressbar-label {
            line-height: 0.6 !important;
            font-size: 0.85rem !important;
        }
    }

}