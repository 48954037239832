.p-toast-message-icon {
    display: none !important;
}

.p-dialog-title {
    font-size: 1rem !important;
}

.p-dialog-header {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-datatable-tbody tr:not(.p-datatable-emptymessage) td {
    text-align: right !important;
}

.p-datatable-tbody tr:not(.p-datatable-emptymessage) td.expander {
    text-align: center !important;
    width: 1.5rem;
}

.p-row-toggler:hover {
    color: #2196F3 !important;
    border-color: unset !important;
    background: unset !important;
}

.p-row-toggler:focus {
    box-shadow: unset !important;
}

.p-row-toggler {
    width: 1rem !important;
    height: 1rem !important;
}

.p-row-toggler-icon.pi.pi-chevron-right {
    rotate: 180deg !important;
}

.p-paginator-icon.pi.pi-angle-left,
.p-paginator-icon.pi.pi-angle-double-left,
.p-paginator-icon.pi.pi-angle-right,
.p-paginator-icon.pi.pi-angle-double-right,
.p-datepicker-prev-icon.pi.pi-chevron-left,
.p-datepicker-next-icon.pi.pi-chevron-right {
    transform: rotate(180deg) !important;
}

.p-datatable .p-paginator-bottom {
    border-width: 0 0 0 0 !important;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

// .p-datatable th,
// .p-datatable td {
//     white-space: nowrap !important;
//     overflow: hidden !important;
//     text-overflow: ellipsis !important;
// }

.p-tooltip .p-tooltip-text {
    white-space: nowrap !important;
}

.p-datatable th,
.p-datatable td {
    padding: 0.6rem !important;
}

.p-datatable .p-datatable-thead>tr>th {
    text-align: right !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    font-size: 0.95rem !important
}

.p-datatable .p-sortable-column:focus {
    box-shadow: unset !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-left: unset !important;
    margin-right: 0.5rem !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    font-size: 1.18rem !important;
}

.p-menu {
    width: 9rem !important;
}

.p-menu .p-menuitem-link:focus {
    box-shadow: unset !important;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
}

.p-datatable-emptymessage td {
    text-align: center !important;
}

.p-datatable-scrollable .p-datatable-tbody>.p-datatable-emptymessage>td {
    display: block !important;
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: unset !important;
    margin-right: unset !important;
}

.p-button-sm.p-button-loading .p-button-icon {
    font-size: 1rem !important;
    margin-top: 2px !important;
}

.p-dialog .p-dialog-content {
    padding-top: 0.4rem !important;
    padding-bottom: 1.2rem !important;
}

.p-button .p-button-icon-left {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
}

.p-datatable-header {
    padding: 0.35rem !important;
    overflow-x: hidden !important;
}

.p-datepicker:not(.p-datepicker-inline) {
    min-width: 360px !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0 !important;
}

.p-dialog .p-dialog-header {
    padding-bottom: 0.4rem !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
    outline: unset !important;
    outline-offset: -0.15rem !important;
}

.field-checkbox>label,
.field-radiobutton>label {
    margin-left: unset !important;
    margin-right: 0.5rem !important;
}

.p-dialog-header-close {
    margin-left: -0.5rem !important;
}

.p-inline-message .p-inline-message-icon {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
}

.p-timepicker {
    direction: ltr !important;
}

.p-calendar>.p-inputtext {
    direction: ltr !important;
    text-align: right !important;
}

.p-autocomplete>.p-autocomplete-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.p-autocomplete>.p-autocomplete-dropdown {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.p-autocomplete>.p-autocomplete-loader {
    right: calc(100% - 50px) !important;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.8 !important;
}

.p-card-content {
    padding-top: 0 !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.7rem !important;
    margin-right: unset !important;
}

.p-dropdown .p-dropdown-clear-icon {
    right: unset !important;
    left: 2.357rem !important;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.5rem !important;
}

.p-tag {
    padding: 0.1rem 0.3rem !important;
    font-size: 0.83rem !important;
    font-weight: normal !important;
}

.p-datatable-row-expansion>td {
    padding-top: 0.2rem !important;
}

.p-panel-header,
.p-fieldset .p-fieldset-legend {
    padding: 0.34rem !important;
    font-weight: 500 !important;
    font-size: 0.9rem !important;
}

.p-panel-content,
.p-fieldset .p-fieldset-content {
    padding: 0.5rem !important;
}

.p-datatable.p-datatable-sm .p-datatable-thead>tr>th,
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.34rem 0.34rem !important;
}

.p-datatable.p-datatable-sm .p-paginator-element {
    min-width: 1.8rem !important;
    height: 1.85rem !important;
}

.p-panel-title {
    display: block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 1.5 !important;
}

.p-tag-info {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
    min-width: 1.3rem !important;
}

.p-sortable-column .pi-sort-amount-up-alt:before {
    content: "\e9a1" !important;
}

.p-sortable-column .pi-sort-amount-down:before {
    content: "\e9a2" !important;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: unset !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: unset !important;
    margin-left: 0.5rem !important;
}

.p-accordion-toggle-icon.pi.pi-chevron-right {
    transform: rotate(180deg) !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: #FFFFFF !important;
    padding: 0.6rem !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: unset !important;
}

.p-accordion-header-link:focus {
    z-index: unset !important;
}

.p-accordion .p-accordion-header:not(.p-disabled):hover .p-accordion-header-link {
    color: unset !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
}